import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useUser, useSupabaseClient } from '@supabase/auth-helpers-react';
import { useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { UserContext } from './_app';
import Head from 'next/head';
import NextLink from 'next/link';
import Image from 'next/image';
import { Statsig } from 'statsig-react';
// import { logAmplitudeEvent } from '../../lib/amplitude';

import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

// import { renderAuthUI } from '../../lib/utils';

function renderAuthUI(supabaseClient, sign_up, redirect_url) {
  
    return (
        <Auth
          redirectTo={redirect_url}
          appearance={{
              theme: ThemeSupa,
              className: {
                  button: 'btn',
                  anchor: 'link text-black',
                  label: 'label-text text-black',                  
                  container: 'w-[300px] md:w-[480px] lg:w-[640px]'
              },            
              variables: {
                default: {
                  colors: {
                    brand: 'hsl(219 14.085% 27.843%)',
                    brandAccent: 'hsl(219 14.085% 22.275%)',
                  },
                },
              },
          }}        
          supabaseClient={supabaseClient}
          providers={['google']}
          view={sign_up ? 'sign_up' : 'sign_in'}
          // socialLayout="horizontal"
        />
    )    
}

export async function getServerSideProps({ query }) {
  return {
    props: {
      sign_up: query.sign_up || false,
      redirect_url: query.redirect_url || "/profile"
    },
  };
}

const LoginPage = (props) => {
  // 
  const supabaseClient = useSupabaseClient()
  const user = useUser()
  const router = useRouter()
  const { userData, updateUserData } = useContext(UserContext);
  const sign_up = props.sign_up;
  const redirect_url = props.redirect_url || "/profile";

  if (user) { router.push('/profile') }

  useEffect(() => {
    Statsig.logEvent('Login Page Viewed', null, {sign_up: sign_up});
}, []); 

  // useEffect(() => {
  //   if (user) {
  //     console.log(user, user.app_metadata);
  //     const createdDate = new Date(user.created_at).toDateString();
  //     const lastSignInDate = new Date(user.last_sign_in_at).toDateString();
  //     console.log(createdDate, lastSignInDate)      
  //     const isNewUser = createdDate === lastSignInDate;
      
  //     if (isNewUser) {
  //       logAmplitudeEvent('User Registered', {
  //         provider: user.app_metadata.provider
  //       });
  //       Statsig.logEvent('User Registered', null, {provider: user.app_metadata.provider, registration_date: createdDate});
  //     } else {
  //       logAmplitudeEvent('User Signed In', {
  //         provider: user.app_metadata.provider
  //       });
  //       Statsig.logEvent('User Signed In', null, {provider: user.app_metadata.provider, registration_date: createdDate});
  //     }
      
  //     router.push('/profile');
  //   }
  // }, [user]);
  
  if (userData.signedOut) { updateUserData({"display_name": "", "feature_release_emails": 0, "updated": false, "signedOut": false}) }
  
  // const [displayName, setDisplayName] = useState(null);
  // const [featureRelease, setFeatureRelease] = useState(0);
  

  return (
    <>
    <Head>
      <title>Login or Sign Up | StyleSense.io</title>
      <meta
        name="description"
        content="Sign in to your StyleSense account to access our beauty tools personalized to your face and facial features."
        key="desc"
      />
    </Head>     
    <main className="min-h-screen max-w-full overflow-x-hidden box-border flex flex-col justify-start items-center">
      <Navbar />  
        {!user ? renderAuthUI(supabaseClient, sign_up, redirect_url) : 
        <>            
            <div className="bg-base-100 rounded-lg p-2 m-4 min-w-[25%]">
              <div className="mx-2 md:mx-10">
                <h1 className="font-bold text-lg pt-2 pb-4">Account information</h1>
                {/* <br/>               */}
                <h1 className="font-bold">Name</h1>
                <div className="divider my-0"></div> 
                  <div className="flex flex-row">
                  <p></p>
                  <label htmlFor="edit-modal" className="btn btn-square btn-xs btn-ghost">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#1157DF" className="w-5 h-5">
                      <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                    </svg>
                  </label>
                  </div>
                <br/>
                <h1 className="font-bold">{user.app_metadata.provider == 'email' ? 'Your Email' : 'Connected Accounts'}</h1>
                <div className="divider my-0"></div>
                <div className="flex items-center justify-start">
                {user.app_metadata.provider == 'google' ? <Image width={32} height={32} className="w-8 h-8 rounded-full mr-2" src="login_google.svg"/> : null}
                  <p>{user.email}</p>
                </div>
                <br/>
                <h1 className="font-bold">Email Settings</h1>
                <div className="divider my-0"></div>
                <div className="flex items-center justify-start">
                  <input id="feature-release" type="checkbox" defaultChecked={0} className="checkbox checkbox-sm mr-2"/>
                  <p>Notify me when new features are released</p>
                </div>
                <div className="flex items-center pt-4 pb-2">    
                  <button className="btn mx-auto btn-sm" onClick={() => supabaseClient.auth.signOut()}>Sign out</button>
                </div>
              </div>
              <p className="px-2 pt-3 font-light text-sm">If you want to delete your account, <label htmlFor="delete-modal" className="text-red-500 link link-hover">click here</label>.</p>
              <input type="checkbox" id="delete-modal" className="modal-toggle" />
              <div className="modal">
                <div className="modal-box bg-base-100">
                  <label htmlFor="delete-modal" className="btn btn-sm btn-circle absolute right-2 top-2 bg-transparent btn-outline border-none z-[10]">✕</label>
                  <h3 className="font-bold text-lg">Are you sure you want to delete your account?</h3>
                  <p className="my-5">You will be logged out, and will no longer have access. All of your personal data will be deleted.</p>
                  <div className="modal-action">
                    <button className="btn bg-red-500 text-white">Confirm deletion</button>
                  </div>
                </div>
                <label htmlFor="delete-modal" className="modal-backdrop">Close</label>
              </div>              
              <div className="bg-slate-100 dark:bg-slate-800 font-light rounded px-4 py-2 mt-2 mb-0 text-sm">
                Have feedback? <NextLink className="text-blue-500 link link-hover" href="https://www.reddit.com/r/stylesense/">Join the community</NextLink>
              </div>
            </div>            
        </>
        }
      <Footer />
    </main>    
    </>
  )
}

export default LoginPage;
// 

              